import React from "react";
import { Box, Container, Typography, Link, IconButton } from "@mui/material";
import { styled } from "@mui/system";
// import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import SpookipupLogo from "../../assets/logos/logo-white.svg";

const FooterContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 0),
}));

const LogoContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "1rem",
  color: "white",
});

const Logo = styled("img")({
  width: "40px",
  height: "auto",
  marginRight: "10px",
  color: "white",
});

const SocialLinks = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginBottom: "1rem",
  color: "white",
});

const NavLinks = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  marginBottom: "1rem",
  color: "white",
});

const StyledLink = styled(Link)(({ theme }) => ({
  color: "inherit",
  margin: theme.spacing(0, 1, 0.5, 1),
  textDecoration: "none",
  transition: "color 0.3s ease",
  "&:hover": {
    color: "#E777A9",
  },
}));

const StyledIconButton = styled(IconButton)(() => ({
  color: "inherit",
  transition: "color 0.3s ease",
  "&:hover": {
    color: "#E777A9",
    backgroundColor: "transparent",
  },
}));

const Footer = () => {
  return (
    <FooterContainer
      align="center"
      sx={{
        padding: { xs: 4, md: 10 },
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#B2A8D1",
          borderRadius: {
            xs: "30px",
            sm: "40px",
            md: "60px",
            lg: "80px",
          },
          p: { xs: 1.5, sm: 2, md: 3, lg: 4 },
          width: "100%",
          maxWidth: { xs: "100%", sm: "100%", md: "95%", lg: "95%" },
          height: "auto",
          animation: "movingBoxBorder 3s infinite linear",
          border: "4px solid #ffff",
          color: "white",
        }}
      >
        <Container maxWidth="lg">
          <LogoContainer>
            <Logo src={SpookipupLogo} alt="Spookipup Logo" />
            <Typography
              fontFamily={"Rubik Wet Paint"}
              variant="h4"
              component="span"
              sx={{ fontWeight: "bold" }}
            >
              Spookipup
            </Typography>
          </LogoContainer>

          <SocialLinks>
            {/* <StyledIconButton
              href="https://x.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon />
            </StyledIconButton> */}
            <StyledIconButton
              href="https://t.me/spookipup_bot"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TelegramIcon />
            </StyledIconButton>
          </SocialLinks>

          <NavLinks fontWeight={800}>
            {["Home", "About", "HowtoBuy", "Tokenomics", "Roadmap"].map(
              (item) => (
                <StyledLink
                  key={item}
                  href={`#${item.toLowerCase().replace(/\s+/g, "-")}`}
                >
                  {item}
                </StyledLink>
              )
            )}
          </NavLinks>

          <Typography
            variant="body2"
            align="center"
            fontWeight={800}
            sx={{ mt: 2, color: "white" }}
          >
            2024 Copyright by Spookipup - All Rights Reserved
          </Typography>
          <Typography
            variant="caption"
            align="center"
            display="block"
            sx={{ mt: 1, color: "white" }}
          >
            Spookipup does not provide investment advice; all content is for
            informational purposes only. Investing in cryptocurrencies involves
            risks, including volatile market prices and potential loss of
            investment.
          </Typography>
        </Container>
      </Box>
    </FooterContainer>
  );
};

export default Footer;
