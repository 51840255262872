import React, { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PieChart from "../common/PieChart";
import data from "../../data/tokenomics.json";
import money from "../../assets/images/money.svg";
import coin from "../../assets/images/coin.svg";

const Tokenomics = () => {
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const handleAccordionChange = (index) => {
    setExpandedIndex(expandedIndex === index ? -1 : index);
  };

  const AccordionTransitionProps = {
    timeout: {
      enter: 400,
      exit: 400,
    },
  };

  return (
    <Box
      id="tokenomics"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: { xs: 2, sm: 4, md: 8, lg: 10 },
        minHeight: { xs: "auto", md: "100vh" },
      }}
    >
      <Box
        sx={{
          position: "relative",
          backgroundColor: "#BCE7B9",
          borderRadius: {
            xs: "30px",
            sm: "40px",
            md: "60px",
            lg: "80px",
          },
          p: { xs: 1.5, sm: 2, md: 3, lg: 4 },
          width: "100%",
          maxWidth: { xs: "95%", sm: "90%", md: "95%", lg: "95%" },
          border: "4px solid #ffff",
          overflow: "visible",
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 3, sm: 4, md: 5 }}
          alignItems="center"
          justifyContent="center"
        >
          {/* Left Stack */}
          <Stack
            direction="column"
            spacing={{ xs: 3, sm: 4, md: 5 }}
            alignItems="center"
            justifyContent="center"
            sx={{ width: { xs: "100%", md: "45%" } }}
          >
            <Box
              component="img"
              src={coin}
              alt="coin"
              sx={{
                position: "absolute",
                left: { xs: "5%", md: "5%" },
                top: { xs: "-5%", md: "-10%" },
                width: { xs: "50px", sm: "60px", md: "80px" },
                zIndex: 2,
                display: { xs: "none", md: "block" },
              }}
            />

            <Box
              sx={{
                padding: { xs: 2, sm: 3, md: 5 },
                position: "relative",
                backgroundColor: "#ffff",
                borderRadius: {
                  xs: "40px",
                  sm: "60px",
                  md: "80px",
                },
                width: "100%",
                top: { xs: "0%", md: "-10%" },
                border: "4px solid #75E36D",
                color: "black",
                overflow: "hidden",
                zIndex: 1,
              }}
            >
              <Typography
                variant="h3"
                fontFamily={"Rubik Wet Paint"}
                sx={{
                  textAlign: "center",
                  mb: 2,
                  fontSize: {
                    xs: "1.8rem",
                    sm: "2.2rem",
                    md: "3rem",
                  },
                }}
              >
                Tokenomics
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  mb: 2,
                  fontSize: {
                    xs: "0.9rem",
                    sm: "1rem",
                    md: "1.1rem",
                  },
                }}
              >
                {data.description}
              </Typography>
            </Box>

            <Box
              component="img"
              src={coin}
              alt="coin"
              sx={{
                position: "absolute",
                left: { xs: "10%", md: "5%" },
                bottom: { xs: "40%", md: "50%" },
                width: { xs: "50px", sm: "60px", md: "80px" },
                zIndex: 2,
                display: { xs: "none", md: "block" },
              }}
            />

            <Box
              component="img"
              src={coin}
              alt="coin"
              sx={{
                position: "absolute",
                left: { xs: "20%", md: "30%" },
                bottom: { xs: "45%", md: "60%" },
                width: { xs: "50px", sm: "60px", md: "80px" },
                zIndex: 2,
                display: { xs: "none", md: "block" },
              }}
            />

            <Box
              sx={{
                padding: { xs: 2, sm: 3, md: 5 },
                position: "relative",
                backgroundColor: "white",
                borderRadius: {
                  xs: "40px",
                  sm: "60px",
                  md: "80px",
                },
                width: "100%",
                mt: { xs: "0", md: "-20%" },
                bottom: { xs: "0", md: "-15%" },
                border: "4px solid #75E36D",
                color: "black",
                overflow: "hidden",
                zIndex: 1,
              }}
            >
              <PieChart />
            </Box>
          </Stack>

          {/* Right Stack */}
          <Stack
            direction="column"
            spacing={{ xs: 2, sm: 3, md: 5 }}
            alignItems="center"
            justifyContent="center"
            sx={{ width: { xs: "100%", md: "55%" } }}
          >
            <Box
              component="img"
              src={money}
              alt="money"
              sx={{
                top: { xs: "-5%", sm: "-8%", md: "-25%" },
                width: {
                  xs: "100px",
                  sm: "150px",
                  md: "250px",
                  lg: "350px",
                },
                position: "absolute",
                left: { xs: "60%", md: "70%" },
                zIndex: 2,
                transform: "scaleX(-1)",
                display: { xs: "none", md: "block" },
              }}
            />

            <Box
              sx={{
                width: "100%",
                position: "relative",
                top: { xs: "0", md: "15%" },
                zIndex: 2,
              }}
            >
              {data.table.map((tokenomics, index) => (
                <Accordion
                  key={index}
                  expanded={expandedIndex === index}
                  onChange={() => handleAccordionChange(index)}
                  TransitionProps={AccordionTransitionProps}
                  sx={{
                    bgcolor: "white",
                    mb: { xs: 1, sm: 1.5, md: 2 },
                    "&:before": { display: "none" },
                    boxShadow: "none",
                    border: "4px solid #75E36D",
                    borderRadius: "40px !important",
                    overflow: "hidden",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      p: { xs: 1.5, sm: 2, md: 2.5 },
                      "&.Mui-expanded": {
                        minHeight: {
                          xs: 40,
                          sm: 44,
                          md: 48,
                        },
                        "& .MuiAccordionSummary-content": { mb: 0 },
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "0.9rem",
                          sm: "1.1rem",
                          md: "1.2rem",
                        },
                        display: "flex",
                        flexDirection: {
                          xs: "row",
                          sm: "row",
                        },
                        alignItems: "center",
                        gap: { xs: 1, sm: 1 },
                        width: "100%",
                        flexWrap: "nowrap",
                      }}
                    >
                      <Box
                        component="span"
                        sx={{
                          fontWeight: 600,
                          display: "inline",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {tokenomics.category}
                      </Box>
                      <Box
                        component="span"
                        sx={{
                          fontWeight: 200,
                          display: "inline",
                          whiteSpace: "nowrap",
                          fontSize: {
                            xs: "0.85rem",
                            sm: "1rem",
                            md: "1.1rem",
                          },
                        }}
                      >
                        ({tokenomics.allocation}%, {tokenomics.quantity}$)
                      </Box>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      p: { xs: 1.5, sm: 2, md: 2.5 },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "0.9rem",
                          sm: "1rem",
                          md: "1.1rem",
                        },
                      }}
                    >
                      {tokenomics.usage}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default Tokenomics;
