import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { bsc } from "wagmi/chains";
import {
  metaMaskWallet,
  trustWallet,
  coinbaseWallet,
  walletConnectWallet,
  binanceWallet,
  braveWallet,
  phantomWallet,
} from "@rainbow-me/rainbowkit/wallets";

const walletList = [
  {
    groupName: "Recommended",
    wallets: [metaMaskWallet, trustWallet],
  },
  {
    groupName: "Others",
    wallets: [
      coinbaseWallet,
      walletConnectWallet,
      binanceWallet,
      braveWallet,
      phantomWallet,
    ],
  },
];

export const config = getDefaultConfig({
  appName: "Spookipup",
  projectId: "a7bc0798ea43db0560a22fd8c0fc2fab",
  chains: [bsc],
  wallets: walletList,
});
